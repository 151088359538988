import js.array.asList
import react.*
import react.dom.client.createRoot
import react.overview.Overview
import web.dom.document

fun main() {
//    createRoot(document.getElementById("root")!!)
//        .render(ReactRouterDomApp.create())

    val container = document.createElement("div")
    document.body.appendChild(container)

    val element = document.body.children.asList().firstOrNull { element ->
        element.tagName == "META" && element.getAttribute("name") == "PAGE"
    }

    val page = when (
        Page.toPage(
            element?.getAttribute("content")
        )
    ) {
        Page.OVERVIEW -> Overview.create()
        Page.LOGIN -> Login.create()

        Page.NONE -> Login.create()
    }

    createRoot(container).render(page)
}