package extensions

import kotlin.js.Date

fun Long.toFormatedDate(): String {
    val date = Date(this)
    val builder = StringBuilder()
    builder.append(date.toLocaleDateString())
    builder.append(" - ")
    builder.append(date.toLocaleTimeString())
    return builder.toString()
}

fun Int?.getStock(): String {
    return this?.toString() ?: "-"
}