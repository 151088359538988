package react

import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import login
import model.UserLogin
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.section
import web.cssom.*
import web.html.InputType

private val scope = MainScope()

val Login = FC<Props> {

    var username by useState("")
    var password by useState("")

    var showWrongUsernameOrPassword by useState(false)

    section {
//        css {
//            backgroundImage = url("/static/background.png")
//            backgroundSize = BackgroundSize.cover
//            width = 100.pc
//            height = 100.pc
//        }
        div {
            css {
                padding = 5.px
                backgroundColor = Color("#BB0000")
                color = NamedColor.white
            }
            +"Login"
        }
        div {
            input {
                id = "username"
                css {
                    marginTop = 5.px
                    marginBottom = 5.px
                    fontSize = 14.px
                }
                type = InputType.text
                value = username
                placeholder = "Username"
                onChange = { event ->
                    username = event.target.value
                    showWrongUsernameOrPassword = false
                }
            }
        }
        div {
            input {
                id = "password"
                css {
                    marginTop = 5.px
                    marginBottom = 5.px
                    fontSize = 14.px
                }
                type = InputType.password
                value = password
                placeholder = "Password"
                onChange = { event ->
                    password = event.target.value
                    showWrongUsernameOrPassword = false
                }
                onKeyDown = {
                    if (it.key == "Enter") {
                        scope.launch {
                            val response = login(
                                UserLogin(
                                    username = username,
                                    password = password
                                )
                            )
                            if (response != null) {
                                delay(1000)
                                window.location.href = window.location.origin
                            } else {
                                showWrongUsernameOrPassword = true
                            }
                        }
                    }
                }
            }
        }
        div {
            button {
                +"Login"
                onClick = {
                    scope.launch {
                        val response = login(
                            UserLogin(
                                username = username,
                                password = password
                            )
                        )
                        if (response != null) {
                            delay(1000)
                            window.location.href = window.location.origin
                        } else {
                            showWrongUsernameOrPassword = true
                        }
                    }
                }
            }
        }
        if (showWrongUsernameOrPassword) {
            div {
                +"Wrong username or password"
            }
        }
    }
}