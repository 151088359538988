package model

import kotlinx.serialization.Serializable

@Serializable
data class SearchJtlItem(
    val itemName: String,
    val itemSku: String,
    val imageBase64: String?
)
