package extensions

import emotion.react.css
import react.dom.html.TdHTMLAttributes
import web.cssom.*
import web.html.HTMLTableCellElement

fun TdHTMLAttributes<HTMLTableCellElement>.setCssForTdTr(center: Boolean = false, backgroundRed: Boolean) {
    css {
        border = Border(1.px, LineStyle.solid)
        textAlign = if (center) {
            TextAlign.center
        } else {
            TextAlign.left
        }
        padding = 8.px

        if (backgroundRed) {
            backgroundColor = Color("#FF0000")
        }
    }
}