package model

enum class Shop(val shopName: String) {
    SHOP_APOTHEKE_DE("Shop Apoptheke DE - Smart7"),
    SHOP_APOTHEKE_AT("Shop Apoptheke AT - Smart7"),
    SHOP_APOTHEKE_IT("Shop Apoptheke IT - Smart7"),
    MEDIA_MARKT_DE("Media Markt DE - Nero"),
    MEDIA_MARKT_IT("Media Markt IT - Nero"),
    MEDIA_MARKT_AT("Media Markt AT - Nero"),
    MEDIA_MARKT_ES("Media Markt ES - Nero"),
    KAUFLAND_DE("Kaufland DE - Smart7"),
    KAUFLAND_CZ("Kaufland CZ - Smart7");

    companion object {
        fun fromString(value: String): Shop? {
            return try {
                valueOf(value)
            } catch (e: IllegalArgumentException) {
                null
            }
        }
    }
}