package react.overview

import emotion.css.keyframes
import emotion.react.css
import getShopProducts
import kotlinext.js.js
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import logout
import model.Shop
import model.Relation
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.thead
import react.dom.svg.ReactSVG
import react.useState
import web.cssom.*

private val scope = MainScope()

val Overview = FC {
    var data by useState(emptyList<Relation>())

    var initialLoad by useState(true)

    var selectedShop by useState(Shop.SHOP_APOTHEKE_DE)

    if (initialLoad) {
        scope.launch {
            data = getShopProducts(
                shop = selectedShop
            )
        }
        initialLoad = false
    }

    var isLoading by useState(false)

    if (isLoading) {
        val spin = keyframes {
            from {
                transform = rotate(0.deg)
            }
            to {
                transform = rotate(360.deg)
            }
        }

        div {
            css {
                position = Position.fixed
                top = 0.px
                left = 0.px
                width = 100.vw
                height = 100.vh
                backgroundColor = Color("#00000080")
                display = Display.flex
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
            }
            ReactSVG.svg {
                width = 50.0
                height = 50.0
                viewBox = "0 0 50 50"

                ReactSVG.circle {
                    cx = 25.0
                    cy = 25.0
                    r = 20.0
                    fill = "none"
                    strokeWidth = 4.0
                    stroke = "#000000"
                    strokeDasharray = "80"
                    strokeDashoffset = "0"
                    css {
                        transformOrigin = TransformOrigin(GeometryPosition.center, GeometryPosition.center)
                        animation = Animation(
                            name = spin,
                            duration = 2.s
                        )
                        animationIterationCount = AnimationIterationCount.infinite
                    }
                }
            }
        }
    }

    div {
        css {
            display = Display.flex
            justifyContent = JustifyContent.spaceBetween
            alignItems = AlignItems.center
        }
        select {
            css {
                fontSize = 1.2.em
                height = 2.em
                margin = 0.5.em
            }
            value = selectedShop
            Shop.entries.forEach {
                option {
                    value = it
                    +it.shopName
                }
            }
            onChange = {
                val newShop = Shop.valueOf(it.target.value)
                if (selectedShop != newShop) {
                    scope.launch {
                        isLoading = true
                        data = getShopProducts(
                            shop = newShop
                        )
                        isLoading = false
                    }
                }
                selectedShop = newShop
            }
        }

        div {
            css {
                display = Display.flex
                alignItems = AlignItems.center
                cursor = Cursor.pointer
                transition = 0.3.s

                hover {
                    color = Color("#BB0000")
                    fontWeight = FontWeight.bold
                }

                active {
                    transform = scale(0.5)
                }
            }
            +"Logout"
            onClick = {
                scope.launch {
                    isLoading = true
                    logout()
                    isLoading = false
                    window.location.reload()
                }
            }
        }
    }

    table {
        css {
            marginTop = 8.px
            borderCollapse = BorderCollapse.collapse
            width = 100.pct
        }
        thead {
            ReactHTML.tr {
                ReactHTML.th { +"Produktname" }
                ReactHTML.th { +"SKU (${selectedShop.shopName})" }
                ReactHTML.th { +"SKU (WAWI)" }
                ReactHTML.th { +"Produktname WAWI" }
                ReactHTML.th { +"Verfügbar" }
                ReactHTML.th { +"Bestand 0" }
                ReactHTML.th { +"Letzte Änderung" }
                ReactHTML.th { +"Benutzer" }
            }
        }
        tbody {
            data.forEachIndexed { index, relation ->
                ReactHTML.tr {
                    if (relation.jtl == null) {
                        css {
                            backgroundColor = Color("#FFA500")
                        }
                    } else if (index % 2 == 0) {
                        css {
                            backgroundColor = Color("#dddddd")
                        }
                    }
                    @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
                    child(
                        RelationItem, props = js {
                            this["relation"] = relation
                            this["scope"] = scope
                            this["shop"] = selectedShop
                            this["refreshData"] = {
                                scope.launch {
                                    isLoading = true
                                    data = getShopProducts(
                                        shop = selectedShop
                                    )
                                    isLoading = false
                                }
                            }
                        } as RelationItemProps
                    )
                }
            }
        }
    }
}